import { Component, OnInit } from "@angular/core";
import { DocumentsService } from "../documents.service";
import { Router } from "@angular/router";
import {
  trigger,
  style,
  stagger,
  keyframes,
  animate,
  query,
  transition,
} from "@angular/animations";
import { document_update_result } from "../models/document_update_result";
import { ControlRoutes } from "../routes";

@Component({
  selector: "app-submit",
  templateUrl: "./submit.component.html",
  styleUrls: ["./submit.component.scss"],
  animations: [
    trigger("fadeIn", [
      transition("void => *", [
        query(":enter", style({ opacity: 0 }), { optional: true }),

        query(
          ":enter",
          stagger("100ms", [
            animate(
              ".5s ease-in",
              keyframes([
                style({ opacity: 0, transform: "translateY(-75)", offset: 0 }),
                style({
                  opacity: 0.5,
                  transform: "translateY(35)",
                  offset: 0.3,
                }),
                style({ opacity: 1, transform: "translateY(0)", offset: 1 }),
              ])
            ),
          ])
        ),
      ]),
      //   // style({ opacity: 1 }), {optional: true}),

      //   // style({ backgroundColor: 'yellow', opacity: 0 }),
      //   // animate(2000, style({ backgroundColor: 'white', opacity: 1 }))
    ]),
  ],
})
export class SubmitComponent implements OnInit {
  errorMessage: string = "";

  constructor(private docsService: DocumentsService, private router: Router) {}

  confirmPassword: string = "";
  submitting: boolean = false;

  ngOnInit() {}

  onSubmit() {
    this.submitting = true;
    this.errorMessage = "";

    this.docsService.updateDocuments(this.confirmPassword).subscribe(
      (data: any) => {
        this.submitting = false;
        this.docsService.processUpdateResults(data);
        this.router.navigateByUrl(ControlRoutes.Results);
      },
      (err: any) => {
        this.submitting = false;
        this.errorMessage = "Submit updates failed";
      },
      () => console.log("UPDATE REQUEST FIN")
    );

    // this.docsService.updateDocuments(this.confirmPassword)
    //   .subscribe(success => {
    //       if(success) {
    //         this.submitting = false;

    //         this.docsService.processUpdateResults2();

    //         this.router.navigateByUrl(ControlRoutes.Results);
    //         //TODO Eval if navigate too soon
    //       }
    //     }, (err) => {
    //       this.submitting = false;
    //       this.errorMessage = "Submit updates failed";
    //   });
  }

  onCancel() {
    this.router.navigateByUrl(ControlRoutes.Documents);
  }

  get actionItems() {
    return this.docsService.actionItems();
  }
}
