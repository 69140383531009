import { Component, OnInit } from "@angular/core";
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl,
} from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { DocumentsService } from "../documents.service";

@Component({
  selector: "app-image-view",
  templateUrl: "./image-view.component.html",
  styleUrls: ["./image-view.component.scss"],
})
export class ImageViewComponent implements OnInit {
  imageUrl: SafeResourceUrl;
  imageName = ""; //TODO

  constructor(
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private docService: DocumentsService
  ) {}

  ngOnInit() {
    let docId = this.route.snapshot.paramMap.get("docid");
    let imageId = 1; //+this.route.snapshot.paramMap.get("id");

    let path = this.docService.buildImageURL(docId, imageId);
    console.log(path);

    try {
      this.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(path); //"http://localhost:4200/assets/invoice.jpg");
    } catch (error) {
      console.log(error);
    }

    //this.imageName = "image name";
  }
}
