<app-navbar></app-navbar>

<div class="container-fluid mt-3">
    <h4>Documents</h4>

    <app-toolbar [actionItems]="actionItems()"
        (onToolbarSubmit)="onToolbarSubmit()"
        (onToolbarClear)="onToolbarClear()"
        (onToolbarCancel)="onToolbarCancel()"
        >
    </app-toolbar>

    <div class="d-none d-lg-block mb-3">
      <i-feather name="thumbs-up" style="color: var(--success); cursor: pointer;" class="ml-2" (click)="onApproveAllClick()">
      </i-feather>
      <i-feather name="pause-circle" style="color: var(--warning); margin-left: 18px; cursor: pointer;" (click)="onHoldAllClick()">
      </i-feather>
      <i-feather name="thumbs-down" style="color: var(--danger); margin-left: 18px; cursor: pointer;" (click)="onRejectAllClick()">
      </i-feather>
      <i-feather name="edit3" class="rounded" style="color: var(--primary); margin-left: 19px; cursor: pointer;" (click)="onCommentAllClick()">
      </i-feather>
    </div>

    <div class="d-block d-lg-none"> <!-- moble -->
      <div class="mb-3">
        <i-feather name="thumbs-up" class="ml-1" style="color: var(--success);" (click)="onApproveAllClick()" >
        </i-feather>
        <i-feather name="thumbs-down" class="mr-2" style="color: var(--danger); float: right;" (click)="onRejectAllClick()">
        </i-feather>
      </div>

      <div class="card mb-2" *ngFor="let group of documents_grouped">
        <div class="card-header">
          {{group.client}} {{group.client_name}} :: {{group.bank_account_title}} :: {{group.journal_group_description}}
        </div>
        <app-document-item *ngFor="let doc of group.documents" [doc]="doc"
            >
        </app-document-item>
      </div>
    </div>

    <div class="d-none d-lg-block"> <!-- full table -->
      <div class="card mb-2" *ngFor="let group of documents_grouped">
        <div class="card-header">
          {{group.client}} {{group.client_name}} :: {{group.bank_account_title}} :: {{group.journal_group_description}}
        </div>
          <app-document-table [documents]="group.documents"
              (approveAllClick)="onApproveAllClick()"
              (rejectAllClick)="onRejectAllClick()"
              (holdAllClick)="onHoldAllClick()"
              (commentAllClick)="onCommentAllClick()"

              >
          </app-document-table>
        </div>
    </div>

    <!-- <br><button (click)="onDump()">Dump</button> -->
</div>
