import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";
import { document } from "./models/document";
import { Observable, interval } from "rxjs";
import * as _ from "lodash";
import { document_update } from "./models/document_update";
import { document_update_request } from "./models/document_update_request";
import { document_update_result } from "./models/document_update_result";
import { document_group } from "./models/document_group";
import { environment } from "src/environments/environment";
import { DocumentFilter } from "./models/documentFilter";
import { Client } from "./models/client";
import { ControlRoutes } from "./routes";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class DocumentsService {
  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private router: Router
  ) {}

  private _requestBase = environment.zisApiBaseUrl;

  private _documentsTimeoutSec: number = 60 * 5;
  private _documentTimer = 0;
  public documentTimoutMessage = "";

  readonly APPROVAL_ACTION_NONE = " ";
  readonly APPROVAL_ACTION_DISAPPROVED = "X";
  readonly APPROVAL_ACTION_APPROVED = "A";
  readonly APPROVAL_ACTION_HOLD = "(";
  readonly APPROVAL_ACTION_NOHOLD = ")";
  readonly APPROVAL_ACTION_COMMENT = "L";

  private _documents: document[] = [];
  private _documents_grouped: document_group[] = [];

  private _updateResults: document_update_result[] = [];
  private _anyUpdateFailed = false;

  public get documents(): document[] {
    return this._documents;
  }

  public get documents_grouped(): document_group[] {
    return this._documents_grouped;
  }

  public get updateResults(): document_update_result[] {
    return this._updateResults;
  }

  public get anyUpdateFailed(): boolean {
    return this._anyUpdateFailed;
  }

  startDocumentTimer() {
    this.documentTimoutMessage = "";
    this._documentTimer = 0;

    const sub = interval(1000).subscribe((x) => {
      this._documentTimer = this._documentTimer + 1;

      if (this._documentTimer >= this._documentsTimeoutSec) {
        console.log("Doc timer time out");

        this.startOver();
        this.documentTimoutMessage =
          "Due to inactivity the documents have been unlocked.";
        this._documentTimer = 0;
        sub.unsubscribe();
        this.router.navigateByUrl(ControlRoutes.Approval);
      }
    });
  }

  loadDocuments(filter: DocumentFilter): Observable<boolean> {
    const filterQuery = `?client=${filter.client}&sequence_name=${filter.sequenceName}&created_by=${filter.createdBy}&${filter.journalPayroll}&approver=${filter.approver}&partner_name=${filter.partnerName}`;
    console.log(filterQuery);

    return this.http.get(this._requestBase + "documents" + filterQuery).pipe(
      map((data: document_group[]) => {
        this._documents_grouped = data;
        this.buildDocumentsListFromGrouped();
        this.startDocumentTimer();
        this.documentTimoutMessage = "";
        return true;
      })
    );
  }

  loadDocumentsGrouped(): Observable<boolean> {
    return this.http.get(this._requestBase + "documents/grouped").pipe(
      map((data: document_group[]) => {
        this._documents_grouped = data;
        this.buildDocumentsListFromGrouped();
        this.startDocumentTimer();
        this.documentTimoutMessage = "";
        return true;
      })
    );
  }

  buildDocumentsListFromGrouped() {
    this.documents_grouped.forEach((element) => {
      this._documents = this._documents.concat(element.documents);
    });
  }

  updateDocuments(password: string): Observable<document_update_result[]> {
    const updateRequest = new document_update_request();
    updateRequest.password = password;

    const headerKeys = ["sel", "docid", "comment"];
    const resultsArr = this.actionItems();
    this._anyUpdateFailed = false;

    const defaultsObj = _(headerKeys)
      .mapKeys()
      .mapValues(function () {
        return "";
      })
      .value();

    const result = resultsArr.map(function (obj) {
      return _(obj)
        .pick(headerKeys)
        .defaults(defaultsObj) // apply the defaults to get the empty string instead of null for a missing item
        .value();
    });

    updateRequest.documentUpdates = <document_update[]>result;

    return this.http.post<document_update_result[]>(
      this._requestBase + "documents",
      updateRequest
    );
  }

  processUpdateResults(results: any) {
    let docs: document_update_result[] = JSON.parse(results.toString());
    docs.forEach((element) => {
      console.log(element);
      //_.find(this.documents,['docid', element.docid]).succeded = element.succeded;
      element.description = _.find(this.documents, [
        "docid",
        element.docid,
      ]).description;
      element.net = _.find(this.documents, ["docid", element.docid]).net;
      element.action = _.find(this.actionItems(), [
        "docid",
        element.docid,
      ]).action;

      if (element.succeeded === false) {
        this._anyUpdateFailed = true;
      }
    });

    this._updateResults = results;
    //console.log(this._updateResults);
    //console.log('processUpdateResults FIN');
  }

  processDocs() {
    this.actionItems().forEach((element) => {
      console.log(element);
      element.completed = true;
    });
    this._updateResults = []; //TODO: Check back nav to results possible issue
  }

  actionItems(): document[] {
    //TODO check perf on running this everytime vs holding a collection
    return _.filter(this.documents, function (item) {
      return (
        item.sel !== " " &&
        (item.completed == false || item.completed == undefined)
      );
    });
  }

  clearActionItems() {
    //TODO check perf
    this.actionItems().forEach((element) => {
      element.action = "";
      element.sel = " ";
    });
  }

  startOver() {
    //TODO warn or confirm pending change

    this.http.delete(this._requestBase + "documents/startover").subscribe();

    console.log("docsService startOver " + this.actionItems.length);

    this._documents = [];
    this._documents_grouped = [];
  }

  buildImageURL(docid: string, imageId: number) {
    return (
      this._requestBase +
      "documents/" +
      docid +
      "/images/" +
      imageId +
      "?token=" +
      this.authService.getAuthToken()
    ); //TODO build with objects?
  }

  // ================ Client stuff to be moved ================
  getClients(): Observable<Client[]> {
    return this.http.get<Client[]>(this._requestBase + "clients");

    // .pipe(
    //   map((data: Client[]) => {
    //     this._clients = data;

    //     console.log(this._clients);

    //     return true;
    //   })
    // );
  }
}
