import { NgModule } from '@angular/core';

import { FeatherModule } from 'angular-feather';
import { AlertTriangle, Edit3, PauseCircle, ThumbsUp, ThumbsDown, Trash, User, Check, Info, ChevronLeft, X, FileText, XOctagon, Image, Lock, PlayCircle, AlertOctagon, Filter, Truck, Folder }
  from 'angular-feather/icons';

const icons = {
  AlertTriangle,
  Edit3,
  PauseCircle,
  ThumbsUp,
  ThumbsDown,
  Trash,
  User,
  Check,
  Info,
  ChevronLeft,
  X,
  FileText,
  XOctagon,
  Image,
  Lock,
  PlayCircle,
  AlertOctagon,
  Filter,
  Truck,
  Folder
};

@NgModule({
  declarations: [],
  imports: [
    FeatherModule.pick(icons)
  ],
  exports: [
    FeatherModule
  ]
})
export class IconsModule { }
