import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { document } from '../models/document';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  animations: [
    trigger('fade', [
      state('void', style({ opacity: 0 })),

      // state('open', style({ opacity: 1 })),
      // state('closed', style({ opacity: 0 })),

      // transition('open <=> closed', [
      //   animate('2000ms')
      // ]),
      // transition('closed => open', [
      //   animate('2000ms')
      // ])

      // state('void', style({ opacity: 0 })),

      transition('void => *, * => void', [
        animate('500ms ease-in')
      ]),
      // transition('void => *', [
      //   animate('500ms ease-in') //TODO fix
      // ])
    ])
  ]
})
export class ToolbarComponent implements OnInit {
  state:string = 'open';

  @Input() actionItems:document[];

  @Output() onToolbarSubmit = new EventEmitter();
  @Output() onToolbarCancel = new EventEmitter();
  @Output() onToolbarClear = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onSubmitClick() {
    this.onToolbarSubmit.emit();
  }

  onCancelClick() {
    this.onToolbarCancel.emit();
  }

  onClearClick() {
    this.onToolbarClear.emit();
  }

  animateMe() {
    this.state = (this.state === 'closed' ? 'open' : 'closed');
  }

}
