import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { DocumentsComponent } from "./documents/documents.component";
import { ImagesComponent } from "./images/images.component";
import { LoginComponent } from "./login/login.component";
import { ApprovalComponent } from "./approval/approval.component";
import { SubmitComponent } from "./submit/submit.component";
import { authGuard } from "./auth.guard";
import { ResultsComponent } from "./results/results.component";
import { DocumentsParentComponent } from "./documents-parent/documents-parent.component";
import { ImageViewComponent } from "./image-view/image-view.component";
import { ControlRoutes } from "./routes";

const routes: Routes = [
  { path: "", component: LoginComponent },
  { path: ControlRoutes.Login, component: LoginComponent },
  {
    path: "documents",
    component: DocumentsParentComponent,
    canActivate: [authGuard],
    canActivateChild: [authGuard],
    children: [
      { path: "", component: DocumentsComponent },
      { path: "submit", component: SubmitComponent },
      { path: "results", component: ResultsComponent },
      { path: "images", component: ImagesComponent },
      {
        path: ":docid/imageview",
        component: ImageViewComponent,
      },
    ],
  },
  {
    path: "**",
    component: ApprovalComponent,
    canActivate: [authGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
