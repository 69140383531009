import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { DocumentsComponent } from "./documents/documents.component";
import { DocumentItemComponent } from "./document-item/document-item.component";
import { IconsModule } from "./icons/icons.module";
import { ToolbarComponent } from "./toolbar/toolbar.component";
import { LoginComponent } from "./login/login.component";
import { ImagesComponent } from "./images/images.component";
import { DocumentsService } from "./documents.service";
import { DocumentTableComponent } from "./document-table/document-table.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ApprovalComponent } from "./approval/approval.component";
import { SubmitComponent } from "./submit/submit.component";
import { ResultsComponent } from "./results/results.component";
import { DocumentsParentComponent } from "./documents-parent/documents-parent.component";
import { ImagesListComponent } from "./images-list/images-list.component";
import { ImageViewComponent } from "./image-view/image-view.component";
import { CookieService } from "ngx-cookie-service";
import { NgSelectModule } from "@ng-select/ng-select";
import { AuthenticationInterceptor } from "./interceptors/authentication.interceptor";

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    DocumentsComponent,
    DocumentItemComponent,
    ToolbarComponent,
    LoginComponent,
    ImagesComponent,
    DocumentTableComponent,
    ApprovalComponent,
    SubmitComponent,
    ResultsComponent,
    DocumentsParentComponent,
    ImagesListComponent,
    ImageViewComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    IconsModule,
    NgSelectModule,
  ],
  providers: [
    DocumentsService,
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
