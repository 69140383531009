<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  <a class="navbar-brand" href="#" routerLink="/documents"
    >Zenith Web Approval</a
  >

  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarTogglerDemo02"
    aria-controls="navbarTogglerDemo02"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
    <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
      <li class="nav-item active">
        <a class="nav-link" href="#" routerLink="/approval"
          >Approval <span class="sr-only">(current)</span></a
        >
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#" routerLink="/reports">Reports</a>
      </li>

      <li class="nav-item d-lg-none">
        <a class="nav-link" href="#" routerLink="/documents">Preferences</a>
      </li>
      <li class="nav-item d-lg-none active">
        <a class="nav-link" role="button" (click)="onLogout()">Logout</a>
      </li>
    </ul>

    <span class="text-light mr-3 d-none d-lg-inline"
      >Welcome, {{ UserName() }}</span
    >

    <div class="btn-group d-none d-lg-block">
      <div class="btn-group dropleft" role="group">
        <i-feather
          name="User"
          style="cursor: pointer"
          class="text-light"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
        </i-feather>
        <div class="dropdown-menu" style="z-index: 2000">
          <a class="dropdown-item" (click)="onLogout()" role="button">Logout</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="#" routerLink="/documents"
            >Preferences</a
          >
        </div>
      </div>
    </div>
  </div>
</nav>
