import { Component, OnInit, Optional } from "@angular/core";
import { document } from "../models/document";
import { document_update } from "../models/document_update";
import {
  trigger,
  state,
  style,
  transition,
  animate,
  keyframes,
  query,
  stagger,
} from "@angular/animations";
import { DocumentsService } from "../documents.service";
import * as _ from "lodash";
import { Router } from "@angular/router";
import { document_group } from "../models/document_group";
import { browserRefresh } from "../app.component";
import { ControlRoutes } from "../routes";

@Component({
  selector: "app-documents",
  templateUrl: "./documents.component.html",
  styleUrls: ["./documents.component.scss"],
  animations: [
    trigger("fadeIn", [
      transition("void => *", [
        query(":enter", style({ opacity: 0 }), { optional: true }),

        query(
          ":enter",
          stagger("100ms", [
            animate(
              ".5s ease-in",
              keyframes([
                style({ opacity: 0, transform: "translateY(-75)", offset: 0 }),
                style({
                  opacity: 0.5,
                  transform: "translateY(35)",
                  offset: 0.3,
                }),
                style({ opacity: 1, transform: "translateY(0)", offset: 1 }),
              ])
            ),
          ])
        ),
      ]),
      //   // style({ opacity: 1 }), {optional: true}),

      //   // style({ backgroundColor: 'yellow', opacity: 0 }),
      //   // animate(2000, style({ backgroundColor: 'white', opacity: 1 }))
    ]),
  ],
})
export class DocumentsComponent implements OnInit {
  documents: document[];
  documents_grouped: document_group[];

  readonly APPROVAL_ACTION_NONE = " ";
  readonly APPROVAL_ACTION_DISAPPROVED = "X";
  readonly APPROVAL_ACTION_APPROVED = "A";
  readonly APPROVAL_ACTION_HOLD = "(";
  readonly APPROVAL_ACTION_NOHOLD = ")";
  readonly APPROVAL_ACTION_COMMENT = "L";

  constructor(private docsService: DocumentsService, private router: Router) {}

  ngOnInit() {
    this.documents = this.docsService.documents;
    this.documents_grouped = this.docsService.documents_grouped;

    console.log("refresh:" + browserRefresh);
    if (browserRefresh) {
      this.docsService.startOver();
      this.router.navigateByUrl(ControlRoutes.Approval);
    }
  }

  onToolbarSubmit() {
    this.router.navigateByUrl(ControlRoutes.Submit);
  }

  onToolbarClear() {
    this.docsService.clearActionItems();
  }

  onToolbarCancel() {
    this.docsService.startOver();
    this.router.navigateByUrl(ControlRoutes.Approval);
  }

  actionItems() {
    return this.docsService.actionItems();
  }

  onApproveClick(doc) {
    console.log("Approve: " + doc.id);
    //this.approveClick.emit(doc)

    if (doc.action === "Approve") {
      doc.action = undefined;
    } else {
      doc.action = "Approve";
    }

    if (doc.sel === this.APPROVAL_ACTION_APPROVED) {
      doc.sel = this.APPROVAL_ACTION_NONE;
    } else {
      doc.sel = this.APPROVAL_ACTION_APPROVED;
    }
  }

  onHoldClick(doc) {
    console.log("Hold: " + doc.id);
    //this.approveClick.emit(doc)

    if (doc.action === "Hold") {
      doc.action = "";
      doc.sel = this.APPROVAL_ACTION_NONE;
    } else {
      doc.action = "Hold";
      doc.sel = this.APPROVAL_ACTION_HOLD;
    }
  }

  onUnHoldClick(doc) {
    if (doc.action === "Un-Hold") {
      doc.action = "";
      doc.sel = this.APPROVAL_ACTION_NONE;
    } else {
      doc.action = "Un-Hold";
      doc.sel = this.APPROVAL_ACTION_NOHOLD;
    }
  }

  onRejectClick(doc) {
    console.log("Reject: " + doc.id);
    //this.rejectClick.emit(doc)
    if (doc.action === "Reject") {
      doc.action = "";
    } else {
      doc.action = "Reject";
    }

    if (doc.sel === this.APPROVAL_ACTION_DISAPPROVED) {
      doc.sel = this.APPROVAL_ACTION_NONE;
    } else {
      doc.sel = this.APPROVAL_ACTION_DISAPPROVED;
    }
  }

  onCommentClick(doc) {
    console.log("Comment: " + doc.id);
    //this.commentClick.emit(doc)
    if (doc.action === "Comment") {
      doc.action = "";
    } else {
      doc.action = "Comment";
    }

    if (doc.sel === this.APPROVAL_ACTION_COMMENT) {
      doc.sel = this.APPROVAL_ACTION_NONE;
    } else {
      doc.sel = this.APPROVAL_ACTION_COMMENT;
    }
  }

  onApproveAllClick() {
    this.documents.forEach((element) => {
      if (!element.action && !element.isLocked && !element.isOnHold) {
        element.action = "Approve";
        element.sel = this.APPROVAL_ACTION_APPROVED;
      }
    });
  }

  onRejectAllClick() {
    this.documents.forEach((element) => {
      if (
        !element.action &&
        !element.isLocked &&
        !(element.isOnHold && !element.isCanRemoveHold)
      ) {
        element.action = "Reject";
        element.sel = this.APPROVAL_ACTION_DISAPPROVED;
      }
    });
  }

  onHoldAllClick() {
    this.documents.forEach((element) => {
      if (
        !element.action &&
        !element.isLocked &&
        !(element.isOnHold && !element.isCanRemoveHold)
      ) {
        element.action = "Hold";
        element.sel = this.APPROVAL_ACTION_HOLD;
      }
    });
  }

  onCommentAllClick() {
    this.documents.forEach((element) => {
      if (
        !element.action &&
        !element.isLocked &&
        !(element.isOnHold && !element.isCanRemoveHold)
      ) {
        element.action = "Comment";
        element.sel = this.APPROVAL_ACTION_COMMENT;
      }
    });
  }

  onDump() {
    console.log(this.docsService.actionItems());
    //console.log(_.random(1, 100));   //(this.documents, { selected: true }));
    //console.log(_.filter(this.documents, function(item) { return item.selected; }) );
  }
}
